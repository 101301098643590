@import url('https://fonts.googleapis.com/css2?family=Cormorant+Upright:wght@300;400;500;600;700&family=Open+Sans:wght@300;400;500;600;700&display=swap');
:root {
    --font-base: 'Cormorant Upright', serif;
    --font-alt: 'Open Sans', sans-serif;
    /* --color-base: rgb(233, 249, 250); */
    --color-base: #e7f2fc;
    --color-gray: rgb(174, 174, 174);
    --color-white: rgb(255, 255, 255);
    --color-blue: #7c76f0;
    --color-btn: rgb(255, 32, 66);
    --color-black: rgb(0, 0, 0);
}

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
}

a {
    color: white;
    text-decoration: none;
}


/* .slide-bottom {
    -webkit-animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
} */

@-webkit-keyframes slide-bottom {
    0% {
        -webkit-transform: translateY(-25%);
        transform: translateY(-25%);
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@keyframes slide-bottom {
    0% {
        -webkit-transform: translateY(-25%);
        transform: translateY(-25%);
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

.cer {
    display: grid;
    align-content: center;
    justify-content: center;
    background-color: #ffffff;
    height: 100vh;
    width: 100%;
}

.load {
    animation: rotation 2s infinite linear;
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}

body::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
}